@tailwind base;
@tailwind components;
@tailwind utilities;

.App{
min-width: 100vw;
min-height: 100vh;
background-image: url(./../public/images/bg.png);
background-repeat: no-repeat;
background-size: cover;
}

footer{
    text-align: center !important;
}


@layer utilities {
    /* Animations */
    .anim-zoomIn {
       animation: zoomIn 1.4s forwards;
    }
    @keyframes zoomIn {
       0% {
          transform: scale(0.8);
       }
    }
    .anim-fadeIn {
       opacity: 0;
       animation: fadeIn 1.2s ease-in-out forwards;
    }
    @keyframes fadeIn {
       100% {
          opacity: 1;
       }
    }
    .slide-up {
       animation: slideUp 1s;
    }
    @keyframes slideUp {
       0% {
          transform: translateY(50%);
       }
    }
 }
 
